import axios from "axios"
import { API_ADDRESS, API_ERROR_CODES, LOADING_KEYS } from "./constants"
import { getAuthToken, isAuthenticated, setAuthToken } from "./auth"
import { saveCanonicalTopics, setSystemData, setLoading, setActiveRequests } from "../components/app/features/system/system_slice"
import { useDispatch } from 'react-redux';
import ReduxStore from '../components/app/store';

export const sendRequest = (topic, timeSlot) => {
  console.log(topic, timeSlot);
}

const activeAjaxMap = {};

export const apiGet = (urlPiece, paramMap) =>
  new Promise((resolve, reject) => {
    if (activeAjaxMap[urlPiece]) {
      return reject();
    }
    activeAjaxMap[urlPiece] = true;

    paramMap = paramMap || {};
    // Use networkKey from Redux if there isn't one in params.
    if (!paramMap['networkKey']) {
      const networkKey = ReduxStore.getState().system.networkInfo.Key;
      if (networkKey) {
        paramMap['networkKey'] = networkKey;
      }
    }
    const paramStr = Object.keys(paramMap).map((key) => `${key}=${paramMap[key]}`).join('&');

    const config = {};
    if (isAuthenticated()) {
      config['headers'] = { Authorization: `Bearer ${getAuthToken()}` };
    }
    return axios.get(`${API_ADDRESS}/${urlPiece}?${paramStr}`, config)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      })
      .finally(()=>delete activeAjaxMap[urlPiece])

  })

export const apiPost = (urlPiece, data) =>
  new Promise((resolve, reject) => {
    if (activeAjaxMap[urlPiece]) {
      return reject();
    }
    activeAjaxMap[urlPiece] = true;

    const config = {};
    if (isAuthenticated()) {
      config['headers'] = { Authorization: `Bearer ${getAuthToken()}` }
    }
    const networkKey = ReduxStore.getState().system.networkInfo.Key;
    if (networkKey && !data['networkKey']) {
      data['networkKey'] = networkKey;
    }
    return axios.post(`${API_ADDRESS}/${urlPiece}`, data, config)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      })
      .finally(()=>delete activeAjaxMap[urlPiece])

  })


// export const saveAvailability = (availability) => {
//   axios.post(`${API_ADDRESS}/user/login`, data)
//     .then((response) => {
//       let error_message = '';
//       if (state == STATE.PHONE) {
//         if (response.data.error == API_ERROR_CODES.INVALID_REQUEST) {
//           error_message = 'We\'ve already sent a login code to your phone. You have to wait 15 minutes before you can request another one.';
//         } else {
//           error_message = 'You should receive a text with your login code.';
//         }
//         setSnackBarText(error_message);
//         setSnackBarOpen(true);
//         setState(STATE.CODE);
//         return;
//       } else if (state == STATE.CODE) {
//         if (response.data.error) {
//           error_message = 'The code you\'re using has expired. Please request a new one.'
//           setSnackBarText(error_message);
//           setSnackBarOpen(true);
//           return;
//         }
//         if (response.data.token) {
//           setAuthToken(response.data.token);
//           navigate('/app');
//         }
//       }
//     })
//     .finally(()=> setLoading(false));
//
// }


// TODO: Use passed in dispatchFn until you figure out how to update the store
//  outside of a component.
export const fetchTopics = (dispatchFn, origin=null) => {
  // Don't fetch if the topics are already loaded into the store.
  if (ReduxStore.getState().system.canonicalTopics.length) return;
  const params = {};
  if (origin) {
    params['origin'] = origin;
  }
  return apiGet('topics/', params)
    .then((response) => {
      if (response.error) {
        return false;
      }
      dispatchFn(saveCanonicalTopics(response.topics));
      return true;
    });
};



export const firstLoad = (dispatchFn) => {
  return new Promise((resolve, reject) => {
    dispatchFn(setLoading({key:LOADING_KEYS.FIRST_LOAD, value:true}));
    // TODO: Don't do a first load on every page turn when the socket system is
    //  set up. For now, we can cheat with having everything update on each
    //  click.
    // if (ReduxStore.getState().system.user.FirstName) {
    //   return resolve(ReduxStore.getState().system);
    // }
    apiGet('user/first-load')
      .then((response) => {
        if (response.error) {
          return reject(response.error);
        }
        dispatchFn(setSystemData([
          {
            key: 'user',
            value: response.user
          },
          {
            key: 'networkInfo',
            value: response.network
          },
        ]));
        dispatchFn(setLoading({key:LOADING_KEYS.FIRST_LOAD, value:false}));
        fetchTopics(dispatchFn);
        return resolve(response);

      });
  })
}

export const secondLoad = (dispatchFn) => {
  return new Promise((resolve, reject) => {
    dispatchFn(setLoading({key:LOADING_KEYS.SECOND_LOAD, value:true}));
    dispatchFn(setLoading({key:LOADING_KEYS.CONNECTIONS_LOAD, value:true}));
    apiGet('network/user-data')
      .then((data) => {
        let system = [
          {
            key: 'connections',
            value: data.connections
          },
          {
            key: 'totalConnections',
            value: data.total
          },
          {
            key: 'connectedNetworks',
            value: data.networks
          }
        ];
        dispatchFn(setSystemData(system));
        dispatchFn(setLoading({key:LOADING_KEYS.CONNECTIONS_LOAD, value:false}));
      })
    return apiGet('user/second-load')
      .then((data) => {
        let system = [];
        system.push({
          key: 'activeRequests',
          value: data.active_requests
        });
        // Sort Call Logs first.
        data.call_logs.sort((a, b) => new Date(b.Request.TimeSlot) - new Date(a.Request.TimeSlot));
        system.push({
          key: 'callLogs',
          value: data.call_logs
        });
        if (data.user_requests) {
          system.push({
            key: 'userRequests',
            value: data.user_requests
          })
        }
        if (data.connections) {
          system.push({
            key: 'connections',
            value: data.connections
          })
        }
        dispatchFn(setSystemData(system));
        dispatchFn(setActiveRequests(data.active_requests));
        dispatchFn(setLoading({key:LOADING_KEYS.SECOND_LOAD, value:false}));
        return resolve(system);
      });
  });
}

export const apiCancelRequest = (dispatchFn, externalId) => {
  return new Promise((resolve, reject) => {
    apiPost('request/cancel', { external_id: externalId })
      .then((response) => {
        if (response.error) {
          return reject(response.error);
        }
        // On successful cancel - refresh the local userRequest.
        dispatchFn(setSystemData({ key: 'userRequests', value: response.user_requests}));
        return resolve(response);
      })
  });
}

export const apiSaveAvailability = (dispatchFn) => {
  dispatchFn(setLoading({key: LOADING_KEYS.SAVE_AVAILABILITY, value:true}));
  return new Promise((resolve, reject) => {
    const data = {
      availability: ReduxStore.getState().system.availability
    };
    apiPost('availability/update', data)
      .then((response) => {
        if (response.error) {
          alert(response.error_message)
          dispatchFn(setLoading({key: LOADING_KEYS.SAVE_AVAILABILITY, value:false}));
          return reject();
        }
        dispatchFn(setLoading({key: LOADING_KEYS.SAVE_AVAILABILITY, value:false}));
        resolve();
      })

  })
}



